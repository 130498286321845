import { AsyncPipe, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	input,
	OnChanges,
	OnDestroy,
	OnInit,
	output,
	signal,
	SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { debounceTime, distinctUntilChanged, filter, Subject, tap } from 'rxjs';

import { SearchService } from '@@core/services/search.service';
import { AutofocusDirective } from '@@shared/auto-focus';

@Component({
	selector: 'sl-search-box',
	templateUrl: './search-box.component.html',
	styleUrl: './search-box.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AutofocusDirective,
		NgIf,
		AsyncPipe,
		InputTextModule,
		MatTooltipModule,
		MatIcon
	],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: SearchBoxComponent,
		multi: true
	}],
})
export class SearchBoxComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
	autoFocusSignal$ = input<boolean>(false, { alias: 'autoFocus' });
	standaloneSignal$ = input<boolean>(false, { alias: 'standalone' });
	inputDebounceSignal$ = input<number>(0, { alias: 'inputDebounce' });
	valueSignal$ = input<string>(null, { alias: 'value' });
	loadingSignal$ = input<boolean>(null, { alias: 'loading' });
	placeholderSignal$ = input<string>('Search', { alias: 'placeholder' });
	searchIdSignal$ = input<string>('unspecified', { alias: 'searchId' });
	clearSearchOnDestroySignal$ = input<boolean>(true, { alias: 'clearSearchOnDestroy' });
	tooltipPositionSignal$ = input<string>('above', { alias: 'tooltipPosition' });
	disabledSignal$ = input<boolean>(false, { alias: 'disabled' });

	readonly searchChange = output<string>();

	onChangeFunction: (fn: any) => void;
	onTouchedFunction: () => void;

	internalValue$ = signal('');
	internalDisabled$ = signal(false);
	readonly #searchChange$ = new Subject<string>();
	readonly #searchService = inject(SearchService);
	readonly #destroyRef = inject(DestroyRef);

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.searchIdSignal$ && !this.standaloneSignal$) {
			// TODO: this can be leaky, if searchId or standalone changes, we should unsubscribe from the previous searchId
			this.#searchService.getMultiSearch(this.searchIdSignal$())
				.pipe(
					takeUntilDestroyed(this.#destroyRef),
					filter(search => search !== undefined),
					tap(searchTerm => this.internalValue$.set(searchTerm))
				).subscribe();
		}

		if (changes.value) {
			this.internalValue$.set(this.valueSignal$() || '');
		}

		if (changes.disabled) {
			this.internalDisabled$.set(this.disabledSignal$() || false);
		}
	}

	ngOnInit(): void {
		this.#searchChange$
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				distinctUntilChanged(),
				debounceTime(this.inputDebounceSignal$()),
				tap(search => this.#doSearch(search))
			)
			.subscribe();
	}

	onSearchChange(event: Event): void {
		const target = event.target as HTMLInputElement;
		this.internalValue$.set(target.value);
		this.#searchChange$.next(target.value);
	}

	clearSearch(): void {
		this.#doSearch('');
	}

	ngOnDestroy(): void {
		if (!this.standaloneSignal$() && this.clearSearchOnDestroySignal$()) {
			this.clearSearch();
		}
	}

	writeValue(searchTerm: string): void {
		this.internalValue$.set(searchTerm || '');
	}

	setDisabledState(isDisabled: boolean): void {
		this.internalDisabled$.set(isDisabled);
	}

	registerOnChange(fn: () => void): void {
		this.onChangeFunction = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedFunction = fn;
	}

	#doSearch(search: string): void {
		this.internalValue$.set(search || '');
		this.searchChange.emit(search);
		this.onChangeFunction?.(search);

		if (!this.standaloneSignal$() && this.searchIdSignal$()) {
			this.#searchService.multiSearchChanged(this.searchIdSignal$(), search);
			this.#searchService.searchChanged(search);
		}
	}
}
