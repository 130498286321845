<div class="input-container sl-width-100 sl-v1">
	<div class="p-input-icon-right sl-width-100">
		<input 	#inputElement
				pInputText
				type="text"
				class="sl-v1 w-min-280"
				autocomplete="off"
				autocapitalize="false"
				autocorrect="off"
				[placeholder]="placeholderSignal$()"
				[value]="internalValue$()"
				[slAutoFocus]="autoFocusSignal$()"
				[matTooltip]="placeholderSignal$()"
				[matTooltipPosition]="tooltipPositionSignal$()"
				[disabled]="internalDisabled$()"
				(input)="onSearchChange($event)" />
		<mat-icon  *ngIf="!(internalValue$() || loadingSignal$())"  class="search-icon material-symbols-outlined">search</mat-icon>
		<i *ngIf="internalValue$()" class="pi pi-times clickable" (click)="clearSearch(); inputElement.focus()"></i>
		<i *ngIf="loadingSignal$()" class="pi pi-spinner"></i>
	</div>
</div>
